/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import {
  ref,
  watch,
} from '@vue/composition-api'
// import axios from '@axios'
// import store from '@/store'

// eslint-disable-next-line no-unused-vars
export default function useItemHandler(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const ItemLocal = ref(JSON.parse(JSON.stringify(props.myrowData.value)))

  watch(props.myrowData, () => {
    ItemLocal.value = JSON.parse(JSON.stringify(props.myrowData.value))
    // console.log('ItemLocal.value', ItemLocal.value)
  })

  const photos = ref([])
  const items = ref([])

  const onclick = val => {
    // photos.value = ItemLocal.value.photo.map(x => `${require(`@/assets/images/banner/${x}`)}`)
    // photos.value = [`data:image/jpg;base64, ${ItemLocal.value.photo}`]
    photos.value = [`${val}mobile/worksafe/${ItemLocal.value.photo}`]
    items.value = photos.value
    // items.value = ItemLocal.photo
    // console.log('this is image console:', ItemLocal.value)
  }

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  watch(props.isWorksafeHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        // clearForm.value()
      }, 350)
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    ItemLocal,
    photos,
    items,
    // index,
    onclick,
    // resetTaskLocal,
  }
}
