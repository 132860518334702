<template>
  <b-overlay
    :show="$store.state.worksafe.isLoading"
    rounded="sm"
  >
    <b-card>
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h4>{{ pageTitle }}</h4>
        </b-col>

        <!-- Filters -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="byStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              class="filter-select d-inline-block mr-1"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <v-select
              v-model="byDate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dateOptions"
              :clearable="false"
              class="filter-select"
              placeholder="Select Date"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

      <!-- Content body -->
      <b-list-group
        ref="refWorkSafeList"
        flush
        class="border-top border-bottom mt-2"
      >
        <b-list-group-item
          v-for="item in items"
          :key="item.id"
          class="list-inline cursor-pointer"
          @click="handleItemClick(item)"
        >
          <span class="list-inline-item mr-2">
            <strong>
              {{
                formatDate(item.date_end, {
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              }}
            </strong>
          </span>
          <span class="list-inline-item">
            <p>
              <span class="text-capitalize text-primary">
                {{ item.cargiverName }}
              </span>
              <span>marked the shift for</span>
              <span class="text-capitalize text-primary">
                {{ item.clientName }}
              </span>
              <span
                class="text-uppercase"
                :class="item.isWorksafe !== 'yes' ? 'text-warning' : 'text-success'"
              >
                {{ item.isWorksafe === 'yes' ? 'safe' : 'hazardous' }}
              </span>
            </p>
          </span>
        </b-list-group-item>
        <b-list-group-item v-if="items.length == 0">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <h5>No matching records found</h5>
          </div>
        </b-list-group-item>
      <!-- <b-list-group-item v-if="$store.state.worksafe.isLoading">
        <div
          class="d-flex justify-content-center"
        >
          <b-spinner
            type="grow"
            label="Loading..."
            style="width: 2.5rem; height: 2.5rem;"
          />
        </div>
      </b-list-group-item> -->
      </b-list-group>

      <!-- Sidebar Handler -->
      <work-safe-handler-sidebar
        v-model="isWorksafeHandlerSidebarActive"
        :clear-row-data="clearRowData"
        :myrow-data="myrowData"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import store from '@/store'
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BOverlay,
  // BFormSelect,
  // BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  // BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import workSafeStoreModule from './worksafeStoreModule.js'
import useWorkSafe from './useWorkSafe.js'
import WorkSafeHandlerSidebar from './WorkSafeHandlerSidebar.vue'

export default {
  components: {
    // BFormInput,
    // BInputGroup,
    // BInputGroupPrepend,
    // BDropdown,
    // BDropdownItem,
    // BFormCheckbox,
    // BBadge,
    // BAvatar,
    // draggable,
    BOverlay,
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    vSelect,
    WorkSafeHandlerSidebar,
  },
  setup() {
    const WORKSAFE_APP_STORE_MODULE_NAME = 'worksafe'

    // Register module
    if (!store.hasModule(WORKSAFE_APP_STORE_MODULE_NAME)) {
      store.registerModule(WORKSAFE_APP_STORE_MODULE_NAME, workSafeStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORKSAFE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(WORKSAFE_APP_STORE_MODULE_NAME)
      }
    })
    const pageTitle = 'Worksafe'
    const isWorksafeHandlerSidebarActive = ref(false)
    const myrowData = ref({})
    const handleItemClick = rowData => {
      myrowData.value = rowData
      // console.log('this is console:', rowData)
      isWorksafeHandlerSidebarActive.value = !isWorksafeHandlerSidebarActive.value
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    watch(isWorksafeHandlerSidebarActive, val => {
      const appContainer = document.querySelector('#app')
      if (!val) {
        appContainer.style.overflow = ''
      } else {
        appContainer.style.overflow = 'hidden'
      }
    })

    const {
      // ----- UI ----- //
      items,
      dateOptions,
      statusOptions,
      byStatus,
      byDate,
      refWorkSafeList,
      getItems,
      clearRowData,
    } = useWorkSafe()

    return {
      pageTitle,
      items,
      dateOptions,
      statusOptions,
      byStatus,
      byDate,
      refWorkSafeList,
      getItems,
      handleItemClick,
      isWorksafeHandlerSidebarActive,
      clearRowData,
      myrowData,

      formatDate,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/pages/app-todo.scss";
</style>
