/* eslint-disable no-return-assign */
import axios from '@axios'
import Vue from 'vue'
import { reactive } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

// DECLARE DATE
const date = new Date()

// FORMAT DATE
date.value = moment(date).format('YYYY-MM-DD')

export default {
  namespaced: true,
  state: reactive({
    isLoading: true,
    byStatus: { label: 'Show All', value: '' },
    byDate: { label: 'Today', value: date.value },
    items: [],
    // rawItems: [],
  }),
  mutations: {
    SET_ITEMS: (state, payload) => (state.items = payload),
    // SET_RAW_ITEMS: (state, payload) => (state.rawItems = payload),
    SET_ROWS: (state, payload) => (state.totalRows = payload),
    SET_FILTERS: (state, payload) => (state.filter = payload),
    SET_BYSTATUS: (state, payload) => (state.bySatus = payload),
    SET_BYDATE: (state, payload) => (state.byDate = payload),
    SET_LOADING: (state, payload) => (state.isLoading = payload),
  },
  getters: {
    // getItems: state => state.items,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getItems(ctx, paramFilter) {
      const uData = localStorage.getItem('userData')
      const accessToken = localStorage.getItem('accessToken')
      // console.log('date.value store: ', date.value)
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}WorkSafe/fetchData`, {
            param: {
              userData: JSON.parse(uData),
              date_today: date.value,
              filterParams: paramFilter,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-type': 'application/json',
            },
          })
          .then(response => {
            resolve(response)
            ctx.commit('SET_LOADING', false)
          })
          .catch(error => { reject(error) })
      })
    },
    //* NOT IN USE *//

    // filter(ctx, payload) {
    //   if (payload[0].value !== '' && payload[1].value !== '') {
    //     const filteredData = ctx.state.rawItems.filter(
    //       f => (f.isWorksafe.toLowerCase() === payload[0].value)
    //         && (moment(f.date_end).format('YYYY/MM/DD') <= date.value)
    //         && moment(f.date_end).format('YYYY/MM/DD') >= payload[1].value,
    //     )
    //     ctx.commit('SET_ITEMS', filteredData)
    //     console.log('this is console both: ', filteredData)
    //   }
    //   if (payload[0].value !== '' && payload[1].value === '') {
    //     const filteredData = ctx.state.rawItems.filter(f => (f.isWorksafe.toLowerCase() === payload[0].value))
    //     ctx.commit('SET_ITEMS', filteredData)
    //     console.log('this is status safetiness: ', filteredData)
    //   }
    //   if (payload[0].value === '' && payload[1].value !== '') {
    //     const fetchedDate = payload[1].value
    //     const filteredData = ctx.state.rawItems
    //       .filter(
    //         f => (moment(f.date_end).format('YYYY/MM/DD') <= date.value)
    //           && moment(f.date_end).format('YYYY/MM/DD') >= fetchedDate,
    //       )
    //     ctx.commit('SET_ITEMS', filteredData)
    //     console.log('this is fetch date: ', payload[1].value)
    //   }
    //   if (payload[0].value === '' && payload[1].value === '') {
    //     const filteredData = ctx.state.rawItems
    //     ctx.commit('SET_ITEMS', filteredData)
    //     console.log('this is console', filteredData)
    //   }
    // },
    // getfilterData(ctx) {
    //   const filteredData = ctx.state.rawItems
    //     .filter(
    //       f => moment(f.date_end).format('YYYY/MM/DD') === date.value,
    //     )
    //   ctx.commit('SET_ITEMS', filteredData)
    // },
  },
}
