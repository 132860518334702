import store from '@/store'
import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWorkSafe() {
  // Use toast
  const toast = useToast()

  // DECLARE DATE
  const date = new Date()
  const sevenDays = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  const thistMonth = date.getMonth() === 11 ? new Date(date.getFullYear()) : new Date(date.getFullYear(), date.getMonth())

  // FORMAT DATE
  date.value = moment(date).format('YYYY-MM-DD') // formatDate(date, { year: 'numeric', month: 'numeric', day: 'numeric' })
  sevenDays.value = moment(sevenDays).format('YYYY-MM-DD') // formatDate(sevenDays, { year: 'numeric', month: 'numeric', day: 'numeric' })
  thistMonth.value = moment(thistMonth).format('YYYY-MM-DD') // formatDate(thistMonth, { year: 'numeric', month: 'numeric', day: 'numeric' })

  const statusOptions = ref([
    { label: 'Show All', value: '' },
    { label: 'Safe', value: 'yes' },
    { label: 'Hazardous', value: 'no' },
  ])

  const dateOptions = ref([
    { label: 'Today', value: date.value },
    { label: 'Last 7 Days', value: sevenDays.value },
    { label: 'This Month', value: thistMonth.value },
    { label: 'All Shift', value: '' },
  ])

  // filters, search, pagination

  const refWorkSafeList = ref(null)

  const byStatus = ref(store.state.worksafe.byStatus)

  const byDate = ref(store.state.worksafe.byDate)

  const items = ref(computed(() => store.state.worksafe.items))

  const isCaregiverHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  const blankData = {
    name: '',
  }

  const filters = [{ label: 'Show All', value: '' }, { label: 'Today', value: date.value }]

  const caregiver = ref(JSON.parse(JSON.stringify(blankData)))

  const clearRowData = () => {
    caregiver.value = JSON.parse(JSON.stringify(blankData))
  }

  const toastNotif = (title, text, icon, variant) => {
    toast({
      component: ToastificationContent,
      props: {
        title, text, icon, variant,
      },
    })
  }

  // ------------------------------------------------
  // get records
  // ------------------------------------------------
  const getItems = paramFilter => {
    const paramFilters = []
    if (paramFilter) {
      paramFilters.push({ byStatus: paramFilter[0].value, byDate: paramFilter[1].value })
    } else {
      paramFilters.push({ byStatus: '', byDate: '' })
    }
    store.commit('worksafe/SET_LOADING', true)
    store.dispatch('worksafe/getItems', paramFilters[0])
      .then(response => {
        store.commit('worksafe/SET_ITEMS', response.data.response.data)
        store.commit('worksafe/SET_LOADING', false)
        // store.dispatch('worksafe/filterByStatus', response.data.response.data)
        // store.commit('worksafe/SET_RAW_ITEMS', response.data.response.data)
        // console.log('this is retriving of data: ', response.data.response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error displaying list',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        store.commit('worksafe/SET_LOADING', false)
        // console.log('this is retriving of data error: ', e)
      })
  }

  /* Copy selected table row data to imputs and show sideBar inputs */
  const editHandler = record => {
    caregiver.value = record
    isCaregiverHandlerSidebarActive.value = true
  }

  /* Filter the items based on selected status */
  watch([byStatus, byDate], val => {
    getItems(val)
    // store.dispatch('worksafe/filter', val)
    // console.log('some val', val[0].label)
  })

  getItems(filters)

  return {
    isCalendarOverlaySidebarActive,
    caregiver,
    clearRowData,
    // fields,
    items,
    // rawItems,
    dateOptions,
    statusOptions,
    byStatus,
    byDate,
    refWorkSafeList,
    editHandler,
    toastNotif,
    getItems,

    // ----- UI ----- //
    isCaregiverHandlerSidebarActive,
  }
}
