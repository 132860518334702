<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isWorksafeHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            WorkSafe
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <div class="m-2">
          <!-- alert -->
          <b-alert
            v-if="ItemLocal.isWorksafe === 'yes'"
            show
            variant="dark"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                size="24"
                class="mr-50 mt-25"
              />
              This shift was marked as
              <span
                class="text-uppercase text-success"
              >
                safe.
              </span>
            </div>
          </b-alert>
          <h5>Shift Details</h5>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="`${urlUpload}clients/${ItemLocal.clientPhoto}`"
                    :text="avatarText(ItemLocal.clientName)"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ ItemLocal.clientName }}
                </span>
                <small class="text-muted">Client</small>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="`${urlUpload}${ItemLocal.cargiverID}/${ItemLocal.cargiverPhoto}`"
                    :text="avatarText(ItemLocal.cargiverName)"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ ItemLocal.cargiverName }}
                </span>
                <small class="text-muted">Caregiver</small>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{
                    formatDate(ItemLocal.date_end, {
                      weekday: "short",
                      month: "short",
                      year: "numeric",
                      day: "numeric",
                    })
                  }}
                </span>
                <small class="text-muted">
                  {{
                    formatDate(ItemLocal.date_start, { hour: "numeric", minute: "numeric" })
                  }}
                  -
                  {{
                    formatDate(ItemLocal.date_end, { hour: "numeric", minute: "numeric" })
                  }}
                </small>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <!-- <span
                class="text-wrap"
              >
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="MapPinIcon"
                    size="18"
                  />
                </b-avatar>
                <span class="text-wrap ml-1 .width-5-per">{{ ItemLocal.location }}</span>
              </span> -->
              <b-media vertical-align="top">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <p class="text-justify">
                  {{ ItemLocal.location }}
                </p>
              </b-media>
            </b-col>
          </b-row>
          <hr>
          <h5 class="mt-2">
            WorkSafe
          </h5>
          <b-row class="mt-1 d-none">
            <b-col>
              <span
                class="text-nowrap"
              >
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="BriefcaseIcon"
                    size="18"
                  />
                </b-avatar>
                <span class="text-nowrap ml-1 .width-5-per text-capitalize">{{ ItemLocal.worksafe === 'yes' ? 'safe' : 'hazardous' }}</span>
              </span>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <!-- <span
                class="text-wrap text-justify border-primary"
              >
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="18"
                  />
                </b-avatar>
                <span class="ml-1 .width-5-per">{{ ItemLocal.worksafecomment ? ItemLocal.worksafecomment : 'n/a' }}</span>
              </span> -->
              <b-media vertical-align="top">
                <template #aside>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      size="18"
                    />
                  </b-avatar>
                </template>
                <p class="text-justify">
                  {{ ItemLocal.worksafecomment ? ItemLocal.worksafecomment : 'n/a' }}
                </p>
              </b-media>
            </b-col>
          </b-row>
          <hr>
          <h5 class="mt-2">
            Photo
          </h5>
          <b-row
            v-if="ItemLocal.photo ? true:false"
            class="mt-1"
          >
            <b-col>
              <!-- <span
                v-for="(myPhoto, inx) in ItemLocal.photo"
                :key="inx"
              >
                <b-img
                  rounded
                  fluid
                  :src="require('@/assets/images/banner/' + myPhoto)"
                  :src="`data:image/png;base64, ${ItemLocal.photo}`"
                  :src="require(`${urlApi}mobile/worksafe/image-${ItemLocal.shiftID}.${ItemLocal.sched_start}.png`)"
                  :alt="myPhoto"
                  class="d-inline-block mr-1 mb-1 pull-up myPhotos"
                  @click="onclick(), index = inx"
                />
                formatDate(ItemLocal.sched_start, { year: "numeric", month: "numeric", day: "numeric", })
              </span> -->
              <span>
                <!-- <b-img
                  rounded
                  fluid
                  :src="`data:image/jpg;base64, ${ItemLocal.photo}`"
                  :alt="ItemLocal.photo"
                  class="d-inline-block mr-1 mb-1 pull-up myPhotos"
                  @click="onclick(), index = 0"
                /> -->
                <b-img
                  rounded
                  fluid
                  :src="`${$apiUrl2}mobile/worksafe/${ItemLocal.photo}`"
                  :alt="'worksafephoto'"
                  class="d-inline-block mr-1 mb-1 pull-up myPhotos"
                  @click="onclick($apiUrl2), index = 0"
                />
              </span>
              <!-- <b-avatar
                v-else
                rounded
                variant="light-secondary"
                size="100"
                style="border-style: dashed;"
              >
                <feather-icon
                  icon="CameraIcon"
                  size="62"
                />
              </b-avatar> -->
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div class="d-flex flex-row justify-content-center align-items-center">
                <h5>No saved photos</h5>
              </div>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="mt-1"
            style="width: 90px"
            @click="hide"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <CoolLightBox
      class="myImage"
      :items="items"
      :index="index"
      :loop="true"
      :effect="'swipe'"
      :slideshow="false"
      :use-zoom-bar="true"
      @close="index = null"
    />
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BAlert,
  BRow,
  BCol,
  BAvatar,
  BMedia,
  BCard,
  BCardText,
  // BFormTextarea,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate, avatarText } from '@core/utils/filter'
import { toRefs } from '@vue/composition-api'
import useItemHandler from './useWorkSafeHandler.js'

export default {
  components: {
    // BSV
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BSidebar,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BAlert,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // BFormTextarea,
    BImg,
    CoolLightBox,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isWorksafeHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isWorksafeHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    myrowData: {
      type: Object,
      required: true,
    },
    clearRowData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      index: null,
      urlUpload: this.$urlUpload,
      urlApi: this.$apiUrl2,
    }
  },
  setup(props, { emit }) {
    const {
      ItemLocal,
      photos,
      items,
      onclick,
      resetTaskLocal,

    } = useItemHandler(toRefs(props), emit)

    return {
      ItemLocal,
      photos,
      items,
      // index,
      onclick,
      resetTaskLocal,

      // UI
      avatarText,
      formatDate,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
  .myPhotos{
    width: 100px !important;
    height:100px !important;
    cursor: pointer;
    object-fit: cover;
  }
</style>
